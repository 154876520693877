export async function handlePaymentRequest(orderId, stateData, finishUrl, errorUrl)
{
    return await fetch('/api/checkout/handle-payment', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            orderId,
            stateData: JSON.stringify(stateData),
            finishUrl,
            errorUrl
        })
    });
}

export function getPaymentTokenFromRedirectUrl(redirectUrl)
{
    let url = new URL(redirectUrl);
    if(url?.searchParams?.get('_sw_payment_token')) {
        return url.searchParams.get('_sw_payment_token');
    } else {
        return false;
    }
}

export async function finalizePayment(paymentToken)
{
    try {
        let paymentFinalizeRequest = await fetch('/api/checkout/finalize-payment', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                paymentToken
            })
        });

        let paymentFinalizeData = await paymentFinalizeRequest.json();

        return !!(paymentFinalizeData && paymentFinalizeData.status === 'success');
    } catch (e) {
        return false;
    }
}

export async function getPaymentMethods() {
    try {
        let requestData = await fetch('/api/checkout/payment-methods', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        let paymentMethodsData = await requestData.json();

        return paymentMethodsData;
    } catch (e) {
        console.log(e);
    }
}

export async function getAdyenPaymentMethods(orderId) {
    let getPaymentMethodsEndpoint = '/api/checkout/adyen-payment-methods';

    if(orderId) {
        getPaymentMethodsEndpoint = getPaymentMethodsEndpoint + '?orderId=' + orderId;
    }

    try {
        let requestData = await fetch(getPaymentMethodsEndpoint, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        let paymentMethodsData = await requestData.json();

        return paymentMethodsData;
    } catch (e) {
        console.log(e);
    }
}

export async function placeOrder(customerComment)
{
    try {
        let orderResponse = await fetch('/api/checkout/place-order', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                customerComment
            })
        });

        let order = await orderResponse.json();

        if (order && order.status === 'success') {
            return {
                status: 'success',
                order: order.data,
                orderId: order.data.id
            };
        } else if (order.status === 'error') {
            return {
                status: 'error',
                errorCode: order.errorCode
            };
        }
    } catch (e) {
        return {
            status: 'error',
            errorCode: 0
        }
    }
}